/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  Fetcher,
  FetcherUpdateStatus,
  NotOkFetchResponse,
  RequestConfig
} from 'packages/http-client/fetcher';
import {
  PermissionCache,
  PermissionResolver,
  PermissionStatusTuple
} from './permission-resolver';

export class MissingPermissionError extends Error {
  constructor(permission: string) {
    super(`Expected permissions to contain ${permission} but didn't`);
  }
}

export const createFetcherPermissionsResolver = <P extends string = string>(
  fetcher: Fetcher,
  createRequestConfig: (permissions: P[]) => RequestConfig,
  getPermissionsFromResponse: (
    response: Response,
    data: any
  ) => { [perm in P]: boolean },
  // Could be toLowerCase() to normalize the permission name to always use lower case
  normalizePermission: (input: P) => P = (value) => value
): PermissionResolver<P> => {
  const listeners: Array<() => void> = [];
  // The source of truth for the permission status:
  // - undefined -> inactive (never requested or fetched)
  // - null -> loading
  // - Error -> error
  // - boolean -> result
  const permissionCache: { [p in P]?: null | Error | boolean } = {};
  // Tracks the off unsubscriber from the all fetcher.
  // Initialize it to noop to prevent condition later
  let offAllPermissionsUpdated = () => {
    // noop
  };
  // Tracks the last known "loading" value for the all permissions fetch.
  // This allows us to re-fetch all permissions or just the newly
  // requested perms when the throttle triggers
  let shouldRefreshAllPermissionsNext = true;

  // Given a fetcher status update and a set of permissions, update our cache to reflect the latest
  const updateState = (
    [loading, response, data, error]: FetcherUpdateStatus,
    relevantPermissions: P[]
  ) => {
    const errorResult =
      error ||
      (response && !response.ok
        ? new NotOkFetchResponse(response!, data)
        : null);

    const emptyPermissions: PermissionCache<P> = {};
    const receivedPermissions =
      !loading && !errorResult
        ? getPermissionsFromResponse(response!, data)
        : emptyPermissions;

    // Update all the relevant permissions
    relevantPermissions.forEach((perm) => {
      permissionCache[perm] = errorResult
        ? errorResult // Error status
        : perm in receivedPermissions
        ? receivedPermissions[perm] // Success status
        : loading
        ? null // Loading status
        : new MissingPermissionError(perm); // TODO: do we need this case? Server did not give us a permission back that we wanted...
    });
    listeners.forEach((listener) => listener());
  };

  // Make a new request for the permissions and update our global fetch listener to listen
  // for changes to any of the permissions
  const fetchPermissions = (newlyRequested: P[]) => {
    // We might need to refresh ALL the permissions or just the new batched ones
    // depending on if we already have an outstanding request for all the permissions
    // This should usually be false. It will only be true if new permissions are requested
    // around the same time a mutating response arrives
    const shouldRefreshAllPermissions = shouldRefreshAllPermissionsNext;

    // Make a new fetcher that will be refreshed as needed. We do not preemptively
    // start this request though
    const allActivePermissions = Object.keys(permissionCache) as P[];
    const [makeAllPermsRequest, onAllPermsUpdated] = fetcher(
      createRequestConfig(allActivePermissions)
    );
    // Stop listening for updates on all the previous permissions being updated
    offAllPermissionsUpdated();
    // Listen for the new all permissions to be updated. Note that we are not starting this request
    offAllPermissionsUpdated = onAllPermsUpdated(
      ([loading, response, data, error]) => {
        // Keep track of the last known global "loading" flag
        shouldRefreshAllPermissionsNext = loading;
        // Make sure we do not notify of empty states where there is no request at all
        if (loading || response || error) {
          updateState([loading, response, data, error], allActivePermissions);
        }
      }
    );

    if (shouldRefreshAllPermissions) {
      // Trigger the all perms request immediately
      makeAllPermsRequest(true);
    } else {
      // Make a new fetcher for just the batched permissions
      const [makeBatchedPermsRequest, onBatchedPermsUpdated] = fetcher(
        createRequestConfig(newlyRequested)
      );
      // Trigger the batched request immediately
      makeBatchedPermsRequest(true);
      // Listen for changes here just until the first status that is no longer loading
      const offBatchedPermissionsUpdated = onBatchedPermsUpdated(
        ([loading, response, data, error]) => {
          if (!loading) {
            // After we get the first non-loading status, we stop listening.
            // Any future updates for those permissions will occur on the all-permissions
            offBatchedPermissionsUpdated();
          }
          updateState([loading, response, data, error], newlyRequested);
        }
      );
    }
  };

  const getPermissionStatusTuple = <R extends P>(
    requestedPermissions: Record<R, R>
  ): PermissionStatusTuple<R> => {
    let errorResult: Error | null = null;
    const permResult: PermissionCache<R> = {};
    for (const requestedPermission in requestedPermissions) {
      const normalizedPermission = requestedPermissions[requestedPermission];
      const entry = permissionCache[normalizedPermission];
      // Found a loading state, we will skip all the rest and indicate that
      if (entry === null) {
        return [true, null, null];
      }
      // If its a boolean value save it in the result obj
      if (!!entry === entry) {
        permResult[requestedPermission] = entry as boolean;
      } else {
        // Otherwise assume its an error
        errorResult = entry as Error;
      }
    }
    return [false, errorResult, errorResult ? null : permResult];
  };

  // Return the actual PermissionResolver
  return <R extends P>(
    requestedPermissions: readonly R[],
    onUpdate: (status: PermissionStatusTuple<R>) => void
  ) => {
    // Normalize all the permissions, keeping a map of them
    const normalizedRequestedPermissionMap = Object.create(null) as Record<
      R,
      R
    >;
    const normalizedPermissions = requestedPermissions.map(
      (perm) =>
        (normalizedRequestedPermissionMap[perm] = normalizePermission(
          perm
        ) as R)
    );

    // Wrap the listener to only be notified of its own status; checking if all the perms are ready and available
    const listener = () =>
      onUpdate(getPermissionStatusTuple(normalizedRequestedPermissionMap));
    listeners.push(listener);

    const unavailablePerms = normalizedPermissions
      // Filter down to those that are newly requested
      .filter(
        (perm) =>
          !(perm in permissionCache) || permissionCache[perm] instanceof Error
      )
      // Mark the perms as loading using null
      .map((perm) => (permissionCache[perm] = null) || perm);

    if (unavailablePerms.length) {
      // Fetch the new permissions or try again due to failed fetch
      // The listener with all the other listeners as a result of the fetch going out
      fetchPermissions(unavailablePerms);
    } else {
      // If there are no new permissions, call the listener so they can get their current values
      listener();
    }

    return /* off */ () => {
      const index = listeners.indexOf(listener);
      if (index >= 0) {
        listeners.splice(index, 1);
      }
    };
  };
};
